/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --option-background-color: #{$white};
    --option-check-mark-background: #{$black};
    --option-size: 20px;
    --option-size-color: 32px;
    --option-text-color: #{$black}; // Move to root
    --option-border-color: var(--color-dark-gray);

    @include mobile {
        --option-border-color: var(--color-dark-gray);
    }
}

.ProductAttributeValue {
    &-Image-Overlay {
        &::before {
            inset-inline-start: -5px;
            inset-block-start: -5px;
            height: calc(var(--option-size) + 10px);
            width: calc(var(--option-size) + 10px);
        }

        &::after {
            display: none;
        }
    }

    &-Color {
        min-height: var(--option-size);
        width: var(--option-size);
        margin-inline-end: 8px;

        &::before {
            inset-inline-start: -5px;
            inset-block-start: -5px;
            height: calc(var(--option-size) + 10px);
            width: calc(var(--option-size) + 10px);
        }

        &::after {
            display: none;
        }
    }

    &-Color + span {
        font-size: 11px;
        font-weight: 400;
        display: block;
    }

    &-Text {
        span {
            font-size: 12px;
        }

        label {
            &:first-of-type { 
                .input-control { 
                    width: 16px;
                    height: 16px;
                    min-width: 16px;
                    min-height: 16px;
                }

                @include mobile {
                    line-height: 1;
                }
            }
        }
    }

    &-String {
        padding: 0 10px;
        margin-block-end: 6px;
        width: 72px;
        height: 32px;
        font-size: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        // margin-inline-end: 2px;

        @include mobile {
            font-size: 12px;
        }

        &_isSelected {
            border-width: 2px;
            --option-text-color: var(--primary-base-color);
        }
    }

    &-SubLabel {
        font-weight: 600;
        font-size: 10px;
    }

    &-Image {
        margin-block-end: 0px;
        /* stylelint-disable-next-line declaration-no-important */
        width: 20px !important;
        height: 20px;
        object-fit: unset;
        /* stylelint-disable-next-line declaration-no-important */
        display: inline-block !important;
        vertical-align: inherit;

        @include mobile {
            // margin: 10px;
        }
    }

    &-IsSquare {
        width: 50px;
        height: 28px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-inline-end: 16px;
        margin-block-end: 10px;
        overflow: hidden;
        
        .ProductAttributeValue-Image {
            /* stylelint-disable-next-line declaration-no-important */
            width: 50px !important;
            height: 28px;
            border-style: solid;
            border-radius: var(--button-border-radius);
            border-color: var(--option-border-color);
            border-width: 1px;
            object-fit: cover;
            margin: 0 auto;

            &-Overlay {
                &::before {
                    width: 50px;
                    height: 28px;
                    border-radius: 4px;
                    inset-block-start: 0px;
                    inset-inline-start: 0px;
                }
                
                &::after {
                    display: none;
                }
            }
        }
    }

    &_isNotAvailable {
        opacity: 1;
        cursor: pointer;
        pointer-events: inherit;
        
        .ProductAttributeValue-String_isSelected {
            &::after {
                border-top: 1px solid #000000;
                content: '';
                position: absolute;
                inset-inline-start: 0;
                inset-inline-end: -50%;
                transform: rotate(338deg);
                transform-origin: 0 0;
                inset-block-end: 0;
                width: 73px;
            }
        }

        .ProductAttributeValue-String {
            &::after {
                border-top: 1px solid var(--option-border-color);
                content: '';
                position: absolute;
                inset-inline-start: 0;
                inset-inline-end: -50%;
                transform: rotate(338deg);
                transform-origin: 0 0;
                inset-block-end: 0;
                width: 73px;
            }
        }
        
        // .ProductAttributeValue-IsSquare {
        //     &::after {
        //         border-top: 1px solid #b1b1b1;
        //         content: '';
        //         position: absolute;
        //         inset-inline-start: 0;
        //         inset-inline-end: -50%;
        //         transform: rotate(331deg);
        //         transform-origin: 0 0;
        //         inset-block-end: 0;
        //         width: 73px;
        //     }
        // }
    }
}

.unavilable {
    .ProductAttributeValue-String_isSelected {
        &::after {
            border-top: 1px solid #000000;
            content: '';
            position: absolute;
            inset-inline-start: 0;
            inset-inline-end: -50%;
            transform: rotate(338deg);
            transform-origin: 0 0;
            inset-block-end: 0;
            width: 73px;
        }
    }
}


.not_isNotAvailable {
    .ProductAttributeValue-String_isSelected {
        &::after {
            border-top: 1px solid #000000;
            content: '';
            position: absolute;
            inset-inline-start: 0;
            inset-inline-end: -50%;
            transform: rotate(338deg);
            transform-origin: 0 0;
            inset-block-end: 0;
            width: 73px;
        }
    }
}

.ProductConfigurableAttributes-Expandable {
    .ProductAttributeValue-Color {
        min-height: var(--option-size-color);
        width: var(--option-size-color);
        margin-inline-end: 8px;
        margin-inline-start: 8px;
    
        &::before {
            inset-inline-start: -5px;
            inset-block-start: -5px;
            height: calc(var(--option-size-color) + 10px);
            width: calc(var(--option-size-color) + 10px);
        }
    
        &::after {
            display: none;
        }
    }
}


