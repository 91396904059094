/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductWishlistButton {
    position: absolute;
    inset-block-start: 10px;
    inset-inline-end: 20px;
    box-shadow: 0 -1px 11px -3px rgba(148 ,148, 148, 0.57);
    border-radius: 50%;
    width: 28px;
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $white;
    cursor: auto;
    
    &-Button {
        &.Button {
            .HeartIcon {
                height: 16px;

                &:hover {
                    // stroke: $default-primary-base-color;
                    // fill: $white;
                }
            }
        }

        &_isDisabled {
            opacity: 0.5;
            pointer-events: none;
        }
    }
}
