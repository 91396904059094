/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.ProductTab {
    &-Item {
        margin: 0;
        padding-inline-start: 0;
        
        @include mobile {
            flex: 1;
        }

        &:last-child {
            margin: 0;
            padding: 0;
        }

        &::before {
            content: '';
        }

        &_isActive {
            background-color: var(--secondary-base-color);
        }
    }

    &-Button {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
        margin: 0;
        padding: 16px 24px;
        cursor: pointer;
    }
}
