/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductLinks {
    &-Slider {
        margin-block-end: 25px;

        @include mobile {
            margin-inline-start: -16px;
            margin-inline-end: -16px;
        }

        .ProductActions {
            &-AttributesWrapper {
                display: none;
            }
        }

        .SlickSlider {
            @include desktop {
                padding: 0 10px;
            }

            .slick-prev, .slick-next {
                /* stylelint-disable-next-line declaration-no-important */
                display: block !important ;
            }
        }

        .ProductCard {
            @include desktop {
                padding: 0 10px;
            }

            &-Content {
                @include mobile {
                    padding-inline-start: 15px;
                    padding-inline-end: 15px;
                }
            }
        }

        &_isMobileOnethird {
            .SlickSlider {
                .slick-list {
                    @include mobile {
                        /* stylelint-disable-next-line declaration-no-important */
                        padding-inline-start: 0 !important;
                    }
                }
            }
        }
    }

    &-Title {
        font-weight: 700;
        
        @include desktop {
            padding-block-end: 10px;
            font-size: 32px;
            line-height: 32px;
        }
    }
}
