/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductGallerySlider {
    cursor: pointer;
    
    @include desktop {
        width: 566px;
    }

    .Image {
        height: auto;
        padding-block: 0;
    }

    .Image-Image {
        position: inherit;
    }

    .slick-slider {
        position: relative;
        display: block;
        box-sizing: border-box;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -ms-touch-action: pan-y;
        touch-action: pan-y;
        -webkit-tap-highlight-color: transparent;
    }

    .slick-list {
        position: relative;
        overflow: hidden;
        display: block;
        margin: 0;
        padding: 0;

        &:focus {
            outline: none;
        }

        &.dragging {
            cursor: pointer;
            cursor: hand;
        }
    }

    .slick-slider .slick-track,
    .slick-slider .slick-list {
        -webkit-transform: translate3d(0, 0, 0);
        -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }

    .slick-track {
        position: relative;
        inset-inline-start: 0;
        inset-block-start: 0;
        display: block;
        margin-inline-start: auto;
        margin-inline-end: auto;

        &::before,
        &::after {
            content: "";
            display: table;
        }

        &::after {
            clear: both;
        }

        .slick-loading & {
            visibility: hidden;
        }
    }

    .slick-slide {
        float: inline-start;
        height: 100%;
        min-height: 1px;

        [dir="rtl"] & {
            float: inline-end;
        }

        img {
            display: block;
        }

        &.slick-loading img {
            display: none;
        }

        &.dragging img {
            pointer-events: none;
        }

        .slick-initialized & {
            display: block;
        }

        .slick-loading & {
            visibility: hidden;
        }

        .slick-vertical & {
            display: block;
            height: auto;
            border: 1px solid transparent;
        }
    }

    .slick-arrow.slick-hidden {
        display: none;
    }

    /* Arrows */

    .slick-prev,
    .slick-next {
        position: absolute;
        display: block;
        height: 32px;
        width: 32px;
        line-height: 0px;
        font-size: 0px;
        cursor: pointer;
        color: transparent;
        inset-block-start: 50%;
        -webkit-transform: translate(0, -50%);
        -ms-transform: translate(0, -50%);
        transform: translate(0, -50%);
        padding: 0;
        border: none;
        outline: none;
        background: url(../SlickSlider/images/arrow.svg) #fff no-repeat;
        background-position: center;
        border-radius: 50%;
        box-shadow: 0 0 4px 1px rgba(0,0,0, 0.1);
        z-index: 9;

        @include mobile {
            height: 32px;
            width: 32px;
            inset-block-start: 35%;
        }
    }

    // .slick-prev::before,
    // .slick-next::before {
    //     background-color: red;
    //     content: "";
    //     width: 100%;
    //     height: 100%;
    //     display: block;
    // }

    .slick-prev {
        inset-inline-start: 0;
        transform: translate(0, -50%) rotate(180deg);
    }

    .slick-next {
        inset-inline-end: 0;
    }

    /* Dots */

    .slick-dotted.slick-slider {
        inset-block-end: 30px;
    }

    .slick-dots {
        inset-block-end: 0;
        list-style: none;
        text-align: center;
        padding: 0;
        margin: 0;
        width: 100%;
        /* stylelint-disable-next-line declaration-no-important */
        display: flex !important;
        flex-wrap: wrap;

        li {
            position: relative;
            display: inline-block;
            padding: 0;
            cursor: pointer;
            flex-grow: 1;
            margin: 0;

            &::before {
                display: none;
            }

            button {
                border: 0;
                background: transparent;
                display: block;
                outline: none;
                line-height: 0px;
                font-size: 0px;
                color: transparent;
                cursor: pointer;
                // background: $grey-2;
                height: 3px;
                width: 100%;

                &:hover,
                &:focus {
                    outline: none;
                    background: $default-primary-base-color;
                }
            }

            &.slick-active button {
                background: $default-primary-base-color;
            }
        }
    }

    .btn {
        position: absolute;
        inset-block-end: 85px;
        inset-inline-start: 0;
        padding: 14px;
        font-size: 12px;
        background: $white;
        z-index: 2;
        display: flex;
        align-content: center;
        cursor: pointer;
        
        svg {
            margin-inline-end: 5px;
            margin-block-start: -2px;
        }
    }

    .Video {
        &-Player {
            background-color: #f0f2f4;

            @include desktop {
                padding-block: 80px;
            }

            @include mobile {
                padding-block: 90px;
            }

            video {
                width: 100%;
                pointer-events: none;
            }
        }
    }
}

.ProductGallerySlider__thumbnails {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin: 15px 0;

    @include mobile {
        flex-wrap: nowrap;
    }

    .ProductGallerySlider__thumbnail {
        cursor: pointer;
        width: 56px;
        height: 70px;
        position: relative;

        svg {
            width: 35px;
            height: 35px;
            position: absolute;
            inset-block-start: 50%;
            inset-inline-start: 50%;
            z-index: 2;
            transform: translate(-50%, -50%);
        }

        @include mobile {
            margin-block-start: 20px;
            margin-block-end: 30px;
            max-width: 56px;
            width: 100%;
            height: 100%;
        }
    }
}

/* stylelint-disable selector-max-id, declaration-no-important */

video::-webkit-media-controls {
    display: none !important;
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

*::--webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}
/* stylelint-enable selector-max-id, declaration-no-important */
