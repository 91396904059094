/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductSizeChartPopup { 
    .Size_Guide_Page {
        padding-block: 0;
    } 

    .Popup-Content {
        max-width: 850px;

        @include desktop {
            padding: 30px;
        }
    }

    .Popup-mainWrapper {
        @include mobile {
            width: 100%;
        }
    }

    .Popup-Header {
        text-align: end;
    }

    .Popup-CloseBtn {
        position: relative;

        @include mobile {
            inset-inline-end: 10px;
        }
    }

    .pagebuilder-column-group {
        @include mobile {
            flex-direction: column-reverse;
        }

        .pagebuilder-column {
            flex: 1 auto;
            padding: 15px;

            @include mobile {
                /* stylelint-disable-next-line declaration-no-important */
                width: 100% !important;
            }

            @media only screen and (max-width: 400px) {
                padding: 0;
            }
        }
    }

    .size-chart-wrapper {
        @include mobile {
            // padding-inline-start: 10px;
            // padding-inline-end: 10px;
        }

        .pagebuilder-column {
            padding: 10px;
            
            @include desktop {
                width: 50%;
            }
        }

        .pagebuilder-mobile-hidden, .pagebuilder-mobile-only { 
            margin-block-start: 20px;
        }

        li::before {
            position: relative;
        }

        .sizeguide_tab, .tab-align-left {
            display: flex;
            flex-direction: column-reverse;
            align-items: center;
            margin-block-end: 0px;
    
            .Table-Wrapper {
                table {
                    /* stylelint-disable-next-line declaration-no-important */
                    width: 100% !important;
                }
    
                tr {
                    td {
                        font-size: 13px;

                        span {
                            /* stylelint-disable-next-line declaration-no-important */
                            white-space: normal !important;
                        }
                    }
                    
                    &:nth-child(odd) {
                        background-color: $default-secondary-base-color;
                    }
    
                    &:first-child {
                        background-color: transparent;
                    }
                }
    
                p {
                    font-size: 14px;
                }
            }
        }
    
        .tabs-navigation {
            display: flex;
            justify-content: center;
            border: 1px solid #8c8c8c;
            border-radius: 18px;
        
            li {
                margin-block-end: 0;
                border-radius: 18px;
        
                &::before {
                    display: none;
                    position: relative;
                }
        
                a {
                    color : #8c8c8c; 
                    font-weight: 400;
                    padding: 5px 30px 3px;
                    display: block;    
                }
        
                &.ui-state-active {
                    background-color: $default-primary-base-color;
        
                    a {
                        color: $white;
                    }
                }
            }
        }
    }

    h2, h3 {
        font-size: 20px;
        line-height: 24px;
        margin-block-start: 10px;
        margin-block-end: 10px;
        font-weight: 700;
    }

    h4 {
        font-weight: 700;
        font-size: 16px;
        line-height: 1.5;
        margin: 1.5rem 0 0.5rem;
    }
    
    p {
        font-size: 12px;
        line-height: 16px;
        margin-block-end: 10px;
    }

    .Table-Wrapper {
        table {
            tr {
                &:first-child {
                    td {
                        font-weight: 600;
                    }
                }

                td {
                    font-size: 13px;

                    span {
                        /* stylelint-disable-next-line declaration-no-important */
                        white-space: normal !important;
                    }
                }
            }
        }
    }

    .sizeguide_unisex_tab {        
        .tabs-navigation {
            display: flex;
            justify-content: center;
            border: 1px solid #8c8c8c;
            border-radius: 18px;
            align-items: center;
            width: 215px;
    
            li {
                margin-block-end: 0;
                border-radius: 18px;
    
                &::before {
                    display: none;
                }
    
                a {
                    color : #8c8c8c; 
                    font-weight: 400;
                    padding: 5px 30px 3px;
                    display: block;    
                }
    
                &.ui-state-active {
                    background-color: $default-primary-base-color;
    
                    a {
                        color: $white;
                    }
                }
            }
        }
    }
}

.pagebuilder-mobile-hidden {
    @include mobile {
        display: none;
    }
}

.pagebuilder-mobile-only {
    @include desktop {
        display: none;
    }
}
