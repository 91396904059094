/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductGallerySliderPopup {
    align-items: flex-start;

    .imageWrapper {
        height: 100vh;

        .Image {
            height: 100%;
            width: 100%;
            background-color: transparent;
            
            &-Image {
                background-color: transparent;
            }
        }
    }

    .Thumb {
        &-Wrapper {
            display: flex;
            height: 80px;

            button {
                width: 100%;
            }
            
            .Image {
                height: 100%;
                width: 100%;
                background-color: transparent;
            }
        }
    }

    .Gallery {
        &-Wrapper {
            height: 100vh;

            ul {
                li {
                    &::before {
                        display: none;
                    }
                }
            }
        }

        &-Thumb {
            position: fixed;
            inset-block-start: 15px;
            width: 30px;
            inset-inline-start: 15px;

            img {
                position: relative;
                display: none;
            }

            .btn-tab {
                cursor: pointer;
                background: #D0D0D0;
                margin-block: 8px;
                border-radius: 100%;
                height: 10px;
                width: 10px;

                .Image {
                    background-color: transparent;
                }

                img {
                    border-radius: 100%;
                }
            }

            .navigation--active {
                .btn-tab { 
                    background: $default-primary-base-color;
                }
            }
        }
    }

    .Popup {
        &-mainWrapper {
            width: 100%;
            height: 100%;
            margin: 0;
        }

        &-Content {
            width: 100%;
            max-width: 100%;
            background: #E5E5E5;
            max-height: 100%;
            height: 100%;
            padding: 0;
        }

        &-CloseBtn {
            position: fixed;
            inset-block-start: 10px;
            inset-inline-end: 25px;
            background: $white;
            border-radius: 50%;
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            .CloseIcon {
                width: 14px;
                height: 14px;
            }
        }
    }

    .SlickSlider {
        .Image-Image {
            @include mobile {
                position: relative;
            }
        }

        .slick-dots {
            /* stylelint-disable-next-line declaration-no-important */
            display: block !important;
        }
    }
}
