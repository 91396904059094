/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductPage {
    @include desktop {
        max-width: 1180px;
        margin-inline: auto;
    }

    @include mobile {
        padding-block-end: 20px;
        margin-inline-end: 10px;
    }

    &-Wrapper {
        @include  wide-desktop {
            padding-block-start: 30px;
            align-items: start;
            grid-column-gap: 154px;
            padding-inline: 0;
        }

        @include desktop {
            grid-column-gap: 50px;
            padding-block-end: 5px;
        }

        section {
            @include desktop {
                grid-column: 2;
            }

            &.ProductActions-Section_type_short {
                p {
                    strong {
                        font-weight: 600;
                    }
                }
            }
        }

        .ProductTabs {
            border: 0;

            @include desktop {
                margin-block-end: 30px;
            }

            &-Wrapper {
                padding: 0;
                margin-block-start: 15px;
                
                .ExpandableContent {
                    @include desktop {
                        min-height: 170px;
                    }

                    &.ProductOtherDetails {
                        @include mobile {
                            border-bottom: 1px solid var(--expandable-content-divider-color);
                        }
                    }
                    
                    &-Button {
                        @include desktop {
                            display: none;
                        }

                        @include mobile {
                            padding-block-end: 15px;
                        }
                    }

                    &-Content {
                        p {
                            color: $light-primary-color;
                        }

                        ul {
                            li {
                                padding-inline-start: 20px;
                                color: $light-primary-color;
                                line-height: 20px;
                                
                                &::before {
                                    content: '';
                                    inset-inline-start: 0;
                                    width: 5px;
                                    height: 5px;
                                    background-color: $light-primary-color;
                                    border-radius: 50%;
                                    padding: 0;
                                    inset-block-start: 7px;
                                }
                            }
                        }

                        .ProductAttributes {
                            color: $light-primary-color;
                            
                            &-Group {
                                font-size: 20px;
                                padding-block-start: 5px;
                                padding-block-end: 0;
                                line-height: 20px;
                                color: $light-primary-color;
                            }

                            &-AttributeLabel {
                                font-size: 14px;
                                color: $light-primary-color;
                            }

                            &-ValueLabel {
                                font-size: 14px;
                                color: $light-primary-color;
                            }
                        }
                    }

                    &-Heading {
                        text-transform: capitalize;

                        .icon {
                            display: none;
                        }
                    }
                }
            }

            .ProductTab {
                &-Button {
                    font-size: 16px;
                    color: $light-primary-color;
                    text-transform: capitalize;
                    font-weight: 600;
                    padding: 2px 0px;
                    line-height: 14px;
                }

                &-Item {
                    padding-inline-end: 15px;
                    margin-inline-end: 15px;

                    &_isActive {
                        background-color: transparent;
                        
                        .ProductTab-Button {
                            color: $light-primary-color;
                            // text-transform: uppercase;
                            
                            &::after {
                                content: '';
                                position: absolute;
                                width: 100%;
                                height: 2px;
                                background-color: $default-primary-base-color;
                                inset-block-end: -8px;
                                inset-inline-start: 0;
                            }
                        }
                    }

                    &:not(:last-child) {
                        &::after {
                            content: '';
                            width: 1px;
                            height: 100%;
                            background-color: $light-primary-color;
                            position: absolute;
                            inset-inline-end: 0;
                        }
                    }
                }
            }

            & + div {
                @include desktop {
                    padding-inline-start: 10px;
                }
            }
        }
    }

    // .ProductAPlus {
    //     display: none;
    // }

    &_isGiftCardPage {
        .ProductGallery {
            pointer-events: none;
            
            @include desktop {
                margin: 0;
            }
            
            .Image-Image {
                position: relative;
            }
        }
    }
}

