/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/* stylelint-disable declaration-no-important */

.ProductActions {
    @include desktop {
        padding-block-end: 10px;
    }

    .ProductAttributeValue-Color {
        margin-inline-end: 12px;
    }

    .ProductAttributeValue-Color + span {
        display: none;
    }

    .ProductPage {
        &-Breadcrumbs {
            .Breadcrumbs {
                @include mobile {
                    display: block;
                }
            }

            .ContentWrapper {
                padding: 0;
            }
        }
    }

    .CustomHeader {
        @include mobile {
            display: none;
        }
    }

    .Breadcrumbs {
        &-List {
            padding-block-start: 0;
            padding-block-end: 5px;
        }
    }

    &-Title {
        font-size: 24px;
        color: $default-primary-base-color;
        line-height: 32px;
        padding-inline-end: 50px;
        font-weight: 700;

        @include mobile {
            font-size: 16px;
        }
    }

    &-SectionOne {
        .ProductWishlistButton {
            inset-block-start: 2px;
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
        }
    }

    &-SectionTwo {
        display: flex;
        border-bottom: 1px solid $border-color;
        margin-block-end: 20px;
        padding-block-end: 10px;

        @include desktop {
            margin-block-start: 0px;
            font-size: 14px;
            font-weight: 400;
            color: $default-primary-base-color;
            margin-block-end: 25px;
            padding-block-end: 25px;
        }
    }

    &-Price {
        .ProductAttr-Taxes {
            font-size: 14px;
            color: $light-primary-color;
            font-weight: 600;
            line-height: 12px;
            margin-block-end: 0;
            margin-block-start: 10px;

            @include mobile {
                font-size: 12px;
            }
        }
    }

    .ProductConfigurableAttributes {
        &-Title {
            // display: none;
            margin-block-start: 0;
            margin-block-end: 20px;
        } 
        
        &-SwatchList {
            @include desktop {
                margin-block-end: 15px;
            }
        }
    }

    .ProductAttributeValue {
        &-String {
            @include desktop {
                margin-inline-end: 16px;
                font-size: 12px;
            }
        }
    }

    &-FitScale {
        margin: 35px 0 0 0;
        max-width: 450px;

        &::before {
            content: '';
            display: block;
            height: 7px;
            border-bottom: 1px solid #5f5f5f;
        }

        ul {
            margin: 11px 0 0;
            padding: 0;
            list-style: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-pack: center;
            justify-content: center;

            li {
                position: relative;
                -ms-flex-positive: 1;
                flex-grow: 1;
                -ms-flex-preferred-size: 0;
                flex-basis: 0;
                color: #5f5f5f;
                text-align: center;

                &::before {
                    content: '';
                    position: absolute;
                    inset-block-start: -20px;
                    inset-inline-start: 50%;
                    margin-inline-start: -1px;
                    height: 16px;
                    border-left: 1px solid #5f5f5f;
                }

                &.true {
                    color: #1d1d1d;
                    font-weight: 700;

                    &::before {
                        inset-inline-start: 50%;
                        margin-inline-start: -8px;
                        padding-inline-end: 0;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        border: 1px solid #1d1d1d;
                        background: #fff;
                    }

                    &::after {
                        content: '';
                        position: absolute;
                        inset-block-start: -15px;
                        inset-inline-start: 50%;
                        margin-inline-start: -3px;
                        width: 8px;
                        height: 8px;
                        border-radius: 50%;
                        background: #1d1d1d;
                    }
                }

                &:not(:only-child):first-child {
                    text-align: start;
                }

                &:not(:only-child):first-child::before {
                    inset-inline-start: 0;
                    margin-inline-start: 0;
                    padding-inline-end: 0;
                }

                &:not(:only-child):first-child::after {
                    inset-inline-start: 5px;
                    margin-inline-start: 0;
                }

                &:not(:only-child):last-child::before {
                    inset-inline-start: auto;
                    inset-inline-end: 0;
                    margin-inline-start: 0;
                    padding-inline-end: 0;
                }

                &:not(:only-child):last-child {
                    text-align: end;
                }

                &:not(:only-child):last-child::after {
                    inset-inline-start: auto;
                    inset-inline-end: 5px;
                    margin-inline-start: 0;
                }
            }
        }
    }

    &-AddToCartWrapper {
        border-top: 1px solid $border-color;
        padding-block-start: 30px;
        margin-block-end: 35px;

        @media (min-width: 1001px) and (max-width: 1160px) {
            justify-content: flex-start;
        }

        .ProductActions-AddToCart {
            width: auto;
            background-color: $addtobag-backgorund-color;
            border: none;
            font-size: 12px;
            text-transform: none;
            border-radius: 4px;
            min-width: 180px;
            margin-block-start: 0;
            font-weight: 600;
        }
    }

    &-PriceWrapper {
        margin-block-start: 4px;
        display: flex;
        align-items: center;

        .Mrp {
            margin-inline-end: 5px;
        }
    }

    &-ShortDescription {
        padding-inline-end: 15px;

        @include mobile {
            margin-block-start: 28px;
        }
    }

    &-Qty {
        &.Field {
            margin-inline-end: 10px;
            width: 80px;
            
            @include mobile {
                margin-block-end: 20px;
            }

            .FieldSelect {
                &-Select {
                    font-size: 12px;
                    font-weight: 600;
                }
            }
        }

        .Field {
            &-Label {
                position: absolute;
                inset-block-start: -5px;
                font-weight: 600;
                font-size: 10px;
                color: $default-primary-base-color;
            }
        }
    }

    &-AttributesWrapper {
        @include mobile {
            border-bottom: 1px solid $border-color;
            padding-block-end: 25px;
            margin-block-end: 35px;
        }
    }

    .Custom-Breadcrumbs {
        .ContentWrapper {
            padding-inline: 0;
        }
    }

    &-SalableQty {
        font-size: 13px;
        line-height: 12px;
        margin-block-start: 15px;
        color: $discountPrice-color;
    }
}


.Sticky {
    &-Actions {
        position: fixed;
        inset-block-start: -100px;
        width: 100%;
        background-color: $white;
        inset-inline-start: 0;
        z-index: 2;
        padding: 20px 0;
        border-bottom: 1px solid $border-color;
        transition: all 0.5s ease;

        &_isStickHeader {
            inset-block-start: 100px;
        }

        .ContentWrapper {
            display: flex;
            align-items: center;
        }

        .ProductActions {
            &-Title {
                font-size: 12px;
                line-height: 16px;
                font-weight: 400;
                margin: 0;
                padding-inline-end: 15px;
            }

            &-PriceWrapper {
                font-size: 12px;
                margin: 0;

                .ProductPrice {
                    font-size: 12px;
                }
            }
        }

        .AddToCart {
            background-color: $addtobag-backgorund-color;
            width: 300px;
            margin-inline-start: auto;
            border-color: $addtobag-backgorund-color;
            text-transform: none;
            font-weight: 600;
        }

        .AddToCartOOS {
            background: none !important;
            width: 390px;
            text-align: end;
            margin-inline-start: auto;
            border: none !important;
            text-transform: none;
            font-weight: 600;
        }
    }
}
