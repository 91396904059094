/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductSlider {
    width: 100%;

    @include mobile {
        margin-block-start: 14px;
    }

    @include desktop {
        grid-column: 1 / span 2;
    }

    &-Wrapper {
        padding-block: 0;
        max-width: 1180px;

        @include mobile {
            padding-inline: 0;
        }

        .SlickSlider {
            .ProductCard {
                padding: 0 10px;

                @include mobile {
                    padding: 0 5px;
                }

                .ProductConfigurableAttributes {
                    .ProductAttributeValue {
                        &-String {
                            display: none;
                        }
                    }
                }
            }

            .slick-list {
                @include mobile {
                    /* stylelint-disable-next-line declaration-no-important */
                    padding-inline-start: 0 !important;
                }
            }
        }
    }

    &-Title {
        margin: 0;
        text-align: center;
        font-size: 26px;
        line-height: 1;
        margin-block-end: 17px;
        
        @include mobile {
            font-size: 22px;
        }
    }

    .Image_imageStatus_1 {
        mix-blend-mode: multiply;
    }

    &-List {
        text-align: center;
        /* stylelint-disable-next-line declaration-no-important */
        margin-inline-start: 0 !important;
    }
}
