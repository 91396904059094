/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductAPlus {
    .m-5 {
        margin: 5px;
    }

    .m-10 {
        margin: 10px;
    }
    
    .m-20 {
        margin: 20px;
    }

    .m-30 {
        margin: 30px;
    }

    .m-40 {
        margin: 40px;
    }

    .m-50 {
        margin: 50px;
    }

    .m-60 {
        margin: 60px;
    }

    .m-70 {
        margin: 70px;
    }

    .m-80 {
        margin: 80px;
    }

    .m-90 {
        margin: 90px;
    }

    .m-100 {
        margin: 100px;
    }

    .p-5 {
        padding: 5px;
    }

    .p-10 {
        padding: 10px;
    }
    
    .p-20 {
        padding: 20px;
    }

    .p-30 {
        padding: 30px;
    }

    .p-40 {
        padding: 40px;
    }

    .p-50 {
        padding: 50px;
    }

    .p-60 {
        padding: 60px;
    }

    .p-70 {
        padding: 70px;
    }

    .p-80 {
        padding: 80px;
    }

    .p-90 {
        padding: 90px;
    }

    .p-100 {
        padding: 100px;
    }

    .mb-10 {
        margin-block-end: 10px;
    }

    .mb-20 {
        margin-block-end: 20px;
    }

    .mb-30 {
        margin-block-end: 30px;
    }

    .mb-40 {
        margin-block-end: 40px;
    }

    .mb-50 {
        margin-block-end: 50px;
    }

    .mb-60 {
        margin-block-end: 60px;
    }

    .mb-70 {
        margin-block-end: 70px;
    }

    .mb-80 {
        margin-block-end: 80px;
    }

    .mb-90 {
        margin-block-end: 90px;
    }

    .mb-100 {
        margin-block-end: 100px;
    }

    .mt-10 {
        margin-block-start: 10px;
    }

    .mt-20 {
        margin-block-start: 20px;
    }

    .mt-30 {
        margin-block-start: 30px;
    }

    .mt-40 {
        margin-block-start: 40px;
    }

    .mt-50 {
        margin-block-start: 50px;
    }

    .mt-60 {
        margin-block-start: 60px;
    }

    .mt-70 {
        margin-block-start: 70px;
    }

    .mt-80 {
        margin-block-start: 80px;
    }

    .mt-90 {
        margin-block-start: 90px;
    }

    .mt-100 {
        margin-block-start: 100px;
    }

    .pb-10 {
        padding-block-end: 10px;
    }

    .pb-20 {
        padding-block-end: 20px;
    }

    .pb-30 {
        padding-block-end: 30px;
    }

    .pb-40 {
        padding-block-end: 40px;
    }

    .pb-50 {
        padding-block-end: 50px;
    }

    .pb-60 {
        padding-block-end: 60px;
    }

    .pb-70 {
        padding-block-end: 70px;
    }

    .pb-80 {
        padding-block-end: 80px;
    }

    .pb-90 {
        padding-block-end: 90px;
    }

    .pb-100 {
        padding-block-end: 100px;
    }

    .pt-10 {
        padding-block-start: 10px;
    }

    .pt-20 {
        padding-block-start: 20px;
    }

    .pt-30 {
        padding-block-start: 30px;
    }

    .pt-40 {
        padding-block-start: 40px;
    }

    .pt-50 {
        padding-block-start: 50px;
    }

    .pt-60 {
        padding-block-start: 60px;
    }

    .pt-70 {
        padding-block-start: 70px;
    }

    .pt-80 {
        padding-block-start: 80px;
    }

    .pt-90 {
        padding-block-start: 90px;
    }

    .pt-100 {
        padding-block-start: 100px;
    }

    .text-left {
        text-align: start;
    }

    .text-center {
        text-align: center;
    }

    .text-right {
        text-align: end;
    }

    @include desktop {
        .web-font-size-15 {
            font-size: 15px;
            line-height: 25px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 15px;
                line-height: 25px;
            }
        }
    
        .web-font-size-17 {
            font-size: 17px;
            line-height: 27px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 17px;
                line-height: 27px;
            }
        }
    
        .web-font-size-19 {
            font-size: 19px;
            line-height: 29px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 19px;
                line-height: 29px;
            }
        }
    
        .web-font-size-21 {
            font-size: 21px;
            line-height: 31px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 21px;
                line-height: 31px;
            }
        }
    
        .web-font-size-23 {
            font-size: 23px;
            line-height: 33px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 23px;
                line-height: 33px;
            }
        }

        .web-font-size-24 {
            font-size: 24px;
            line-height: 34px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 24px;
                line-height: 34px;
            }
        }
    
        .web-font-size-25 {
            font-size: 25px;
            line-height: 35px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 25px;
                line-height: 35px;
            }
        }
    
        .web-font-size-27 {
            font-size: 27px;
            line-height: 37px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 27px;
                line-height: 37px;
            }
        }
    
        .web-font-size-29 {
            font-size: 29px;
            line-height: 39px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 29px;
                line-height: 39px;
            }
        }
    
        .web-font-size-31 {
            font-size: 31px;
            line-height: 41px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 31px;
                line-height: 41px;
            }
        }
    
        .web-font-size-33 {
            font-size: 33px;
            line-height: 43px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 33px;
                line-height: 43px;
            }
        }
    
        .web-font-size-36 {
            font-size: 36px;
            line-height: 46px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 36px;
                line-height: 46px;
            }
        }
    
        .web-font-size-38 {
            font-size: 38px;
            line-height: 48px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 38px;
                line-height: 48px;
            }
        }
    
        .web-font-size-40 {
            font-size: 40px;
            line-height: 50px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 40px;
                line-height: 50px;
            }
        }
    
        .web-font-size-42 {
            font-size: 42px;
            line-height: 52px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 42px;
                line-height: 52px;
            }
        }
    
        .web-font-size-44 {
            font-size: 44px;
            line-height: 54px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 44px;
                line-height: 54px;
            }
        }
    
        .web-font-size-46 {
            font-size: 46px;
            line-height: 56px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 46px;
                line-height: 56px;
            }
        }
    
        .web-font-size-48 {
            font-size: 48px;
            line-height: 52px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 48px;
                line-height: 52px;
            }
        }
    
        .web-font-size-50 {
            font-size: 50px;
            line-height: 60px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 50px;
                line-height: 60px;
            }
        }
    
        .web-font-size-52 {
            font-size: 52px;
            line-height: 62px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 52px;
                line-height: 62px;
            }
        }
    
        .web-font-size-54 {
            font-size: 54px;
            line-height: 64px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 54px;
                line-height: 64px;
            }
        }
    
        .web-font-size-56 {
            font-size: 56px;
            line-height: 66px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 56px;
                line-height: 66px;
            }
        }
    
        .web-font-size-58 {
            font-size: 58px;
            line-height: 68px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 58px;
                line-height: 68px;
            }
        }
    
        .web-font-size-60 {
            font-size: 60px;
            line-height: 70px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 60px;
                line-height: 70px;
            }
        }
    }

    @include mobile {
        .mobile-font-size-15 {
            font-size: 15px;
            line-height: 25px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 15px;
                line-height: 25px;
            }
        }
    
        .mobile-font-size-17 {
            font-size: 17px;
            line-height: 27px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 17px;
                line-height: 27px;
            }
        }
    
        .mobile-font-size-19 {
            font-size: 19px;
            line-height: 29px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 19px;
                line-height: 29px;
            }
        }
    
        .mobile-font-size-21 {
            font-size: 21px;
            line-height: 31px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 21px;
                line-height: 31px;
            }
        }
    
        .mobile-font-size-23 {
            font-size: 23px;
            line-height: 33px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 23px;
                line-height: 33px;
            }
        }

        .mobile-font-size-24 {
            font-size: 24px;
            line-height: 34px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 24px;
                line-height: 34px;
            }
        }
    
        .mobile-font-size-25 {
            font-size: 25px;
            line-height: 35px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 25px;
                line-height: 35px;
            }
        }
    
        .mobile-font-size-27 {
            font-size: 27px;
            line-height: 37px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 27px;
                line-height: 37px;
            }
        }
    
        .mobile-font-size-29 {
            font-size: 29px;
            line-height: 39px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 29px;
                line-height: 39px;
            }
        }
    
        .mobile-font-size-31 {
            font-size: 31px;
            line-height: 41px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 31px;
                line-height: 41px;
            }
        }
    
        .mobile-font-size-33 {
            font-size: 33px;
            line-height: 43px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 33px;
                line-height: 43px;
            }
        }
    
        .mobile-font-size-36 {
            font-size: 36px;
            line-height: 46px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 36px;
                line-height: 46px;
            }
        }
    
        .mobile-font-size-38 {
            font-size: 38px;
            line-height: 48px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 38px;
                line-height: 48px;
            }
        }
    
        .mobile-font-size-40 {
            font-size: 40px;
            line-height: 50px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 40px;
                line-height: 50px;
            }
        }
    
        .mobile-font-size-42 {
            font-size: 42px;
            line-height: 52px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 42px;
                line-height: 52px;
            }
        }
    
        .mobile-font-size-44 {
            font-size: 44px;
            line-height: 54px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 44px;
                line-height: 54px;
            }
        }
    
        .mobile-font-size-46 {
            font-size: 46px;
            line-height: 56px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 46px;
                line-height: 56px;
            }
        }
    
        .mobile-font-size-48 {
            font-size: 48px;
            line-height: 52px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 48px;
                line-height: 52px;
            }
        }
    
        .mobile-font-size-50 {
            font-size: 50px;
            line-height: 60px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 50px;
                line-height: 60px;
            }
        }
    
        .mobile-font-size-52 {
            font-size: 52px;
            line-height: 62px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 52px;
                line-height: 62px;
            }
        }
    
        .mobile-font-size-54 {
            font-size: 54px;
            line-height: 64px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 54px;
                line-height: 64px;
            }
        }
    
        .mobile-font-size-56 {
            font-size: 56px;
            line-height: 66px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 56px;
                line-height: 66px;
            }
        }
    
        .mobile-font-size-58 {
            font-size: 58px;
            line-height: 68px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 58px;
                line-height: 68px;
            }
        }
    
        .mobile-font-size-60 {
            font-size: 60px;
            line-height: 70px;

            h1, h2, h3, h4, h5, h6 span, p {
                font-size: 60px;
                line-height: 70px;
            }
        }
    }

    .font-weight-100 {
        font-weight: 100;
    }

    .font-weight-200 {
        font-weight: 200;
    }

    .font-weight-300 {
        font-weight: 300;
    }

    .font-weight-400 {
        font-weight: 400;
    }

    .font-weight-500 {
        font-weight: 500;
    }

    .font-weight-600 {
        font-weight: 600;
    }

    .font-weight-700 {
        font-weight: 700;
    }

    .font-weight-800 {
        font-weight: 800;
    }

    @include desktop {
        .width-10 {
            max-width: 10%;
            margin: 0 auto;
        }
        
        .width-20 {
            max-width: 20%;
            margin: 0 auto;
        }
    
        .width-30 {
            max-width: 30%;
            margin: 0 auto;
        }
    
        .width-40 {
            max-width: 40%;
            margin: 0 auto;
        }
    
        .width-50 {
            max-width: 50%;
            margin: 0 auto;
        }
    
        .width-60 {
            max-width: 60%;
            margin: 0 auto;
        }
    
        .width-70 {
            max-width: 70%;
            margin: 0 auto;
        }

        .width-80 {
            max-width: 80%;
            margin: 0 auto;
        }

        .width-90 {
            max-width: 90%;
            margin: 0 auto;
        }
    }

    iframe, video {
        width: 100%;
    }

    h1, h2, h3, h4, h5, h6, p {
        padding: 0;
        margin: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-weight: normal;
        font-family: $font-NeuePlak-Condensed-Black;

        span {
            font-weight: normal;
            font-family: $font-NeuePlak-Condensed-Black;
            letter-spacing: 1px;        }
    }

    .width-auto {
        text-align: center;

        img {
            width: auto;
        }
    }

    .aplus-heading-content {
        padding: 50px 0;

        @include mobile {
            padding: 30px 0;
        }

        h1, h2, h3, h4, h5, h6 {
            margin-block-end: 18px;
            font-size: 50px;
            font-family: $font-NeuePlakBlack-Condensed;
            font-weight: bold;
            letter-spacing: 2px;
            line-height: 1em;
            
            @media(max-width: 1100px) {
                font-size: 35px;
            }

            @include mobile {
                font-size: 30px;
                text-align: center;
                color: $default-primary-base-color;
            }

            @media (max-width: 370px) {
                font-size: 20px;
            }
        }

        p {
            font-size: 14px;
        }
    }
}
