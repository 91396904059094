/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductListBanner {
    width: 100%;

    &::before {
        display: none;
    }

    @include mobile {
        margin-block-end: 20px;
    }

    div[data-content-type="row"] {
        padding-block-start: 10px;

        @include mobile {
            padding-block-start: 0px;
        }
    }

    div[data-content-type="buttons"] {
        padding: 20px 0;

        @include mobile {
            padding: 10px 0;
        }

        a {
            background-color: $white;
            border: 1px solid $default-primary-base-color;
            color: $default-primary-base-color;
            max-width: 288px;
            padding: 10px 40px;
            display: inline-block;
            border-radius: 4px;
            font-weight: 600;
            font-size: 12px;

            @include mobile {
                padding: 10px 10px;
            }
        }
    }

    &.wide {
        grid-column: span 1;

        @include desktop {
            h3 {
                margin: 5px 0;
                padding: 0;
                padding-block-start: 0px;
                font-size: 17px;
                line-height: 23px;
            }
            
            p {
                text-align: justify;
            }
        }

        @include mobile {
            grid-column: span 2;
        }
    }

    h3 {
        margin: 0;
        padding: 0;
        padding-block-start: 20px;

        @include mobile {
            padding-block-start: 10px;
        }
    }

    p {
        margin: 0;
        padding: 0;
    }
}
