/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductConfigurableAttributes {
    &-SwatchList,
    &-DropDownList {
        margin-block-end: 0;
        padding-block-start: 0;
        
        @include mobile {
            // padding-block-end: 10px;
            margin-block-start: 5px;
        }

        .More {
            &-Swatch {
                font-weight: 400;
                color: $default-primary-light-color;
                font-size: 12px;
                margin-block-end: 2px;
            }
        }

        .ExpandableContentShowMore {
            &-ExpandableChildren {
                margin-block-start: -5px;
            }
        }
    }

    [data-attribute-code="filter_color"] {
        @include mobile {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
        }
    }

    &-SwatchList {
        padding-inline-start: 5px;

        

        p {
            font-size: 12px;
        }
    }

    &-Expandable {
        @include desktop {
            border-bottom: 1px solid $border-color;
            padding: 17px 0 15px;
        }
    }

    &-ExpandableContentButton {
        .ChevronIcon {
            width: 14px;
        }
    }

    &-ExpandableContentHeading {
        font-size: 14px;

        @include desktop {
            width: 100%;
        }
    }

    &-SelectedOptionLabel {
        font-size: 14px;
        opacity: 1;
        padding-inline-start: 0;

        &-Title {
            margin-block-end: 20px;
            font-weight: 400;
        }
    }
}
