/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.ProductCard {
    @include mobile {
        margin-block-end: 20px;
    }

    &:hover {
        box-shadow: none;

        .ProductCard-Name {
            text-decoration: underline;
        }
    }

    &-Figure {
        position: relative;

        .Image {
            &-Image {
                width: 100%;
            }
        }

        .Picture_Back {
            position: absolute;
            inset-block-start: 0;
            inset-inline-start: 0;
            z-index: -1;
            // height: auto;
        }

        &:hover {
            .Picture_Back {
                z-index: 0;
            }    
        }
    }

    &-NonLazy {
        min-height: 250px;

        img {
            /* stylelint-disable-next-line declaration-no-important */
            width: 100% !important;
        }
    }

    // &-Picture {
    //     padding-block-end: inherit;
    //     display: block;
    // }

    &-Name {
        text-align: start;
        font-size: 14px;
        color: $default-primary-base-color;
        font-weight: 600;
        line-height: 18px;
        margin-block-end: 5;
        white-space: pre-wrap;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        @include mobile {
            line-height: 14px;
            font-size: 13px;
            margin-block-start: 7px;
        }

        &:hover {
            // text-decoration: underline;
        }
    }

    &-PriceWrapper {
        text-align: start;
    }

    &-Price {
        font-size: 14px;
        font-weight: 400;
        color: $default-primary-base-color;
        display: flex;
        align-items: center;

        .ProductPrice-Price {
            margin-inline-end: 5px;
        }

        @include mobile {
            font-size: 12px;
        }
    }

    &-FigureReview {
        @include desktop {
            padding-inline-start: 0;
            padding-inline-end: 0;
        }
    }

    &-Content {
        padding: 15px 0 0px;
        
        @include mobile {
            padding-block-start: 10px;
        }
        
        .ProductActions-AttributesWrapper {
            @include mobile {
                border-bottom: 0;
                padding-block-end: 0;
                margin-block-end: 0;
            }
        }
    }

    &-Tag {
        position: absolute;
        inset-block-start: 12px;
        inset-inline-start: 12px;
        z-index: 2;
        line-height: 1;

        .Tag {
            font-size: 10px;
            color: $black;
            font-weight: 600;
            text-transform: uppercase;

            @include mobile {
                font-size: 8px;
            }
            
            &-TagImage {
                width: 17px;
                height: 22px;
                margin-inline-end: 8px;
            }

            &-New {
                display: flex;
                align-items: center;
                border-radius: 4px;

                @include mobile {
                    background-color: rgba(255, 255, 255, 0.6);
                    border-radius: 4px;
                    padding: 5px;
                }
            }
            
            &-BestSeller {
                background-color: $white;
                border-radius: 4px;
                padding: 10px;

                @include mobile {
                    background-color: rgba(255,255,255,0.6);
                    padding: 5px;
                }
            }
        }
    }
}

.ProductPrice {
    &-PriceValue {
        line-height: 20px;
        margin-block-start: 0;
    }

    &-PriceBadge {
        display: none;
    }
}

.ProductCard + nav {
    grid-column: span 3;

    @include mobile {
        grid-column: span 2;
    }

    .Pagination {
        /* stylelint-disable-next-line declaration-no-important */
        display: flex !important;
    }
}
