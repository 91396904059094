/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

:root {
    --price-color: #{$black};
    --price-with-discount-color: #DC0019;
    --price-discount-color: #0A0903;
    --discount-color: #00834b;
}

.ProductPrice {
    font-size: 14px;
    font-weight: 400;
    color: $default-primary-base-color;
    min-height: auto;
    line-height: 14px;
    display: flex;
    align-items: center;

    &-HighPrice {
        color: var(--price-with-discount-color);
        line-height: 14px;

        @include desktop {
            line-height: 14px;
        }
    }
    
    del {
        opacity: 1;
        order: 2;
        margin-inline-start: 5px;
        
        @include mobile {
            font-size: 12px;
        }
    }

    &_hasDiscount {
        // color: var(--price-with-discount-color);

        .ProductPrice-PriceValue {
            // color: var(--price-with-discount-color);
        } 

        .Mrp {
            color: #1d1d1d;
            margin-inline-end: 5px;
        }

        .ProductDiscount {
            color: var(--discount-color);
            font-weight: 500;
            order: 3;
            font-size: 14px;
        }
    }

    &-BundleTo {
        margin-inline-start: 10px;
    }
}
